import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Banner from "./Banner";
import Aboutus from "../Components/Aboutus";
import Services from "./Services";
import Portfolio from "../Components/PortfolioData";
import Footer from "./Footer/Footer";

const Homepage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      const section = document.getElementById(location.state.sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <main className="home-main">
      <Header />
      <Banner />
      <Aboutus />
      <Services />
      <Portfolio />
      <Footer />
    </main>
  );
};

export default Homepage;
