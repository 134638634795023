import React from 'react';
import errorimg from "../Images/404.svg";

const NotFoundPage = () => {
    return (
        <section className="errro-page">
            <div className="container d-flex justify-content-center flex-column">
                <img src={errorimg} alt="errorimg" />
                <div className="wrapper">
                    <h1>Page Not Found</h1>
                    <a href="/real-estate-web-solutions-dubai" className='back-home-btn'>Back To Home</a>
                </div>
            </div>
        </section>
    );
};

export default NotFoundPage;
