import React, { useState, useEffect } from "react";
import axios from "axios";


const PortfolioData = () => {

    const [portfolioItems, setPortfolioItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState("all");

    const handleAddPortfolio = async (type) => {
        try {

            const url = `https://perfectwebservices.com/Apis/showportfolio.php?timestamp=${Date.now()}`;

            const response = await axios.get(url);
            if (response.data && response.data.portfolioItems) {
                setPortfolioItems(response.data.portfolioItems);
            } else {
                console.error("Error: Portfolio items not found in API response");
            }
        } catch (error) {
            console.error("Error fetching data:", error.response);
        } finally {

        }
    };


    useEffect(() => {
        handleAddPortfolio(selectedTab);
    }, [selectedTab]);

                        return (
            <section className="our-portfolio" id="portfolio">
                            <div className="container">
                                <div className="row">
                                <p className="sub-heading text-center">Our Portfolio</p>
                                <h2 className="text-center">Excellence From Concept To Completion</h2>
                                <div className="col-md-12">
                                    <div className="gallery-wrap">

                                <ul id="filters" className="clearfix">
                                    {/* <li><span className="filter active" data-att="all" data-filter=".wordpress_development, .web_development, .web_designing, .search_engine_optimization ,.uiuxdesign ,.digital_marketing">All Work</span></li> */}
                                    <li><span className="filter active" data-att="uiuxdesign" data-filter=".uiuxdesign">UI/UX Design</span></li>
                                    <li><span className="filter" data-att="web_designing" data-filter=".web_designing">Web Designing</span></li>
                                    <li><span className="filter" data-att="web_development" data-filter=".web_development">Development</span></li>
                                    <li><span className="filter" data-att="digital_marketing" data-filter=".digital_marketing">Digital Marketing</span></li>
                                </ul>

                            <div id="gallery">

                                {portfolioItems.map((item, index) => (
                                    <a style={{ display: index < 6 ? 'inline-block' : 'none' }} className={`gallery-item ${item.category}`} href={item.url ? item.url : "javascript:void(0)"} data-cat={item.category} key={index}>

                                        <div className="inside">
                                            <div className="details">
                                                <div className="text">
                                                    <h3>{item.title}</h3>
                                                </div>
                                            </div>

                                            <img src={`https://perfectwebservices.com/admin${item.image}`} alt={item.title} />
                                        </div>

                                    </a>
                                ))}

                            </div>

                        </div>
                    </div>
                    <a className="load-more">Show More</a>
                </div>
            </div>
        </section>
    )
}

export default PortfolioData
