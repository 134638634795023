import React from 'react'
import techsolution from "../Images/techsolution.svg"
import quicksupport from "../Images/quick-support.svg"
import Personimg from "../Images/user-profile-img.webp"
import ListIcon from "../Images/list-icon.svg"
import itsolutionsImg from "../Images/itsolution.webp"

const Aboutus = () => {
    return (
        <div className='aboutus-sec' id='aboutus'>
            <div className="container-fluid p-0">
                <div className="row about-row-sec">
                    <div className="col-md-7">
                        <div className="about-company">
                            <p className="sub-heading">
                                About us
                            </p>
                            <h2>Why Our IT Solution is the Smart Choice</h2>
                            <p>Perfect Web Services Company has an experienced and fanatical team of designers and developers. We work ingeniously and ardently to face and win every challenge we come across.</p>

                            <div className="why-select-us">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="why-select-us-box">
                                            <div className="icon-img-select">
                                                <img src={techsolution} alt="techsolution-icon" />
                                            </div>
                                            <div className="select-content">
                                                <h4>Innovative Approach: </h4>
<p>Our team of experts stays updated with the latest technologies to provide innovative web solutions for your business.</p>                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="why-select-us-box">
                                            <div className="icon-img-select">
                                                <img src={quicksupport} alt="techsolution-icon" />
                                            </div>
                                            <div className="select-content">
                                                <h4>Customized Solutions</h4>
<p>We understand that every business is unique. Thus we offer tailored solutions to meet the specific needs of our clients.</p>                                                                                     </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="our-features-details">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="greate-platform">
                                            <div className="user-img">
                                                <img src={Personimg} alt="person-img" />
                                            </div>
                                            <div className="user-content">
                                                <h4><span>Crafting Your Digital Success: </span>Design, Develop, Dominate!</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="user-description">
<p>We realize the specific difficulties that businesses encounter on the web and provide individualized solutions that deliver interaction and conversions.</p>                                            <ul className="features-list">
                                                <li> <img src={ListIcon} alt="list-icon" /> Digital Security</li>
                                                <li> <img src={ListIcon} alt="list-icon" /> Tech Solution</li>
                                                <li> <img src={ListIcon} alt="list-icon" /> Tech Solution</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="it-soluctions-img">
                            <img src={itsolutionsImg} alt="itsolutions-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus
