import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import WebsiteLogo from "../../Images/logo.svg";
import Menuicon from "../../Images/Menu.svg";
import MenuClose from "../../Images/close.svg";

const Headerfull = () => {
  const navigate = useNavigate();

  const handleRedirect = (path, sectionId) => {
    navigate(path, { state: { sectionId } });
  };

  return (
    <div>
      <Helmet>
      <title>Best Web Development Services | Perfect Web Services</title>
                <meta name="title" content="Best Web Development Services | Perfect Web Services" />
                <meta name="description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies." />
                <meta name="keywords" content="Effective Marketing Strategies, Advanced It Solutions, Web Development Services, leading web solution company, creative website design services" />
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Best Web Development Services | Perfect Web Services"/>
                <meta property="og:description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies."/>
                <meta property="og:image" content="https://perfectwebservices.com/sitelogo.png"/>
                <meta property="og:url" content="https://perfectwebservices.com"/>
                <meta property="og:locale" content="en_IN"/>
                <meta property="og:site_name" content="Perfect Web Services"/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content="Best Web Development Services | Perfect Web Services"/>
                <meta property="twitter:description" content="Perfect Web Services provides the Best Web Development Services for your business across the world with advanced technologies & Effective Marketing Strategies."/>
                <meta property="twitter:image" content="https://perfectwebservices.com/sitelogo.png"/>
                <meta property="twitter:url" content="https://perfectwebservices.com"/>
                <link rel="canonical" href="https://perfectwebservices.com"/> 
      </Helmet>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <a className="navbar-brand" href="/">
                <img src={WebsiteLogo} alt="perfect-web-logo" />
              </a>
            </div>
            <div className="col-9">
              <nav className="mobile-offcanvas navbar navbar-expand-lg d-none d-lg-block">
                <div className="offcanvas-header">
                  <button className="btn-close float-end">
                    <img src={MenuClose} alt="MenuClose" />
                  </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#aboutus"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect("/", "aboutus");
                        }}
                      >
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#ourservices"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect("/", "ourservices");
                        }}
                      >
                        Our Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#portfolio"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect("/", "portfolio");
                        }}
                      >
                        Portfolio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#contactus"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRedirect("/", "contactus");
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              <button
                data-trigger="navbar_main"
                className="d-lg-none mobile-menu-btn"
                type="button"
              >
                <img src={Menuicon} alt="menuicon" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Headerfull;
