import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import footerlogo from "../../Images/perfect-web-log.webp";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [websiteUrl, setWebsiteURL] = useState("");
    const [filterType, setFilterType] = useState([]);
    const [tellusaboutyourproject, setTellusaboutyourproject] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailAddressRef = useRef(null);
    const websiteUrlRef = useRef(null);
    const tellusaboutyourprojectRef = useRef(null);
    const filterTypeRef = useRef(null);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!firstName || !lastName || !emailAddress || !filterType) {
            toast.error("All fields are required");
            return;
        }

        if (!isValidEmail(emailAddress)) {
            toast.error("Invalid email address");
            return;
        }

        setIsSubmitting(true); 
        const data = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            websiteUrl: websiteUrl,
            filterType: filterType,
            tellusaboutyourproject: tellusaboutyourproject,
        };

        axios.post("https://perfectwebservices.com/Apis/contactapi.php", data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((res) => {
                setIsSubmitting(false); 
                if (res.data.success) {

                    firstNameRef.current.value = '';
                    lastNameRef.current.value = '';
                    emailAddressRef.current.value = '';
                    websiteUrlRef.current.value = '';
                    tellusaboutyourprojectRef.current.value = '';
                    filterTypeRef.current.value = '';

                    toast.success('Form submited successfully');
                } else {
                    toast.error("Form submission failed");
                }
            })
            .catch((err) => {
                setIsSubmitting(false); 
                toast.error("Something went wrong");
            });
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setWebsiteURL(window.location.href);
            document.getElementById('websiteUrl').value = window.location.href;
        }, 1000); 

        return () => clearTimeout(timeoutId); 
    }, []);

    return (
        <section className='footer-container-sec' id='contactus'>
            <div className='contactus-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Let's Connect To Build<br /><span>The Future Of Your Business</span></h2>
                            <p>We are a dedicated team that works with the aim of developing lasting ties with our customers. Let’s come together over your business and <br />see how we can help you accomplish your online goals. Call Perfect Web Services today to start building an active online presence.</p>
                            <div className="contact-form">
                                <ToastContainer />
                                <h2><span>Contact information</span></h2>
                                <form onSubmit={handleFormSubmit} action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input ref={firstNameRef} required name="firstName" type="text" placeholder='First Name' onChange={(e) => { setFirstName(e.target.value); }} />
                                        </div>
                                        <div className="col-md-6">
                                            <input ref={lastNameRef} onChange={(e) => { setLastName(e.target.value); }} required name="lastName" type="text" placeholder='Last Name' />
                                            <input ref={websiteUrlRef} onChange={(e) => { setWebsiteURL(e.target.value); }} name="websiteUrl" type="hidden" id="websiteUrl" onLoad="document.getElementById('websiteUrl').value = window.location.href;" />
                                        </div>
                                        <div className="col-md-6">
                                            <input ref={emailAddressRef} onChange={(e) => { setEmailAddress(e.target.value); }} required name="emailAddress" type="email" placeholder='Email Address' />
                                        </div>
                                        <div className="col-md-6">
                                            <select ref={filterTypeRef} onChange={(e) => { setFilterType(e.target.value); }} required name="filterType" id="">
                                                <option value="">Select Service</option>
                                                <option value="uiuxdesign">Ui/Ux Design</option>
                                                <option value="web_designing">Web Designing</option>
                                                <option value="web_development">Development</option>
                                                <option value="digital_marketing">Digital Marketing</option>
                                                <option value="react_frontend">React Frontend</option>
                                                <option value="mern_mean">MERN/MEAN</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <textarea ref={tellusaboutyourprojectRef} onChange={(e) => { setTellusaboutyourproject(e.target.value); }} name="tellusaboutyourproject" type="textarea" cols="2" row="3" placeholder='Tell us about your project' />
                                        </div>
                                    </div>
                                    <input type="submit" value={isSubmitting ? "Sending..." : "Get a quote"} disabled={isSubmitting} />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form-holder">
            </div>
            <footer className='footer-sec'>
                <div className="footer-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 footer-logo-brand">
                                <div className="footer-column-sec">
                                    <img src={footerlogo} alt="brand-logo" />
                                    <p> We're a team of passionate developers, designers, <br />and marketers who are dedicated to helping <br/> businesses transform their online presence. </p>                                
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-column-sec">
                                    <h3>Contact Us</h3>
                                    <ul className="contact-links">
                                        <li><a href="tel:+91-8264958742">+91-8264958742</a></li>
                                        <li><a href="tel:+91-7508738954">+91-7508738954</a></li>
                                        <li><a href="mailto:contact@perfectwebservices.com">contact@perfectwebservices.com</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-column-sec">
                                    <h3>Office</h3>
                                    <p>E-237AA, Phase 8B, Industrial Area,<br /> Sector 74, Sahibzada Ajit Singh Nagar, <br />Punjab 160055</p>
                                </div>
                            </div>

                        </div>
                        <div className="row copyright">
                            <div className="col-md-6">
                                <p className='copyright-text'>&copy; 2018-2024 Perfect Web Services. All right reserved</p>
                            </div>
                            <div className="col-md-6">
                                <div className="social-links">
                                    <ul>
                                        <li><a href="https://www.facebook.com/perfectwebservices">Facebook</a></li>
                                        <li><a href="https://www.instagram.com/perfect_web_services/">Instagram</a></li>
                                        <li><a href="https://www.linkedin.com/company/perfect-web-services/">Linkedin</a></li>
                                        <li><a href="https://twitter.com/PerfectWebServ1">Twitter</a></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer
